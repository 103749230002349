import Config from '@docomodigital/js-config';
import Logger from '@docomodigital/js-logger';
import VideoPlayerLibrary from '@docomodigital/videoplayer';
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import NewtonAdapter from 'newton-adapter';

/* COMPONENTS */
import Block from 'components/Block/Block.jsx';

/* LIBRARIES */
import fetcher from 'lib/fetcher';
import parseApiParams from 'lib/parseApiParams';

const videoPlayerNodeID = 'videoplayer';

class VideoPlayer extends PureComponent {
    constructor(props) {
        super(props);

        this.mounted = true;

        this.state = {
            playerId: null,
        };
    }

    componentDidMount() {
        /* GET VIDEO URL */
        const isDesktop = Config.get('UA') === 'DESKTOP';
        let quality;
        if (isDesktop || this.props.isWifi) {
            quality = 'HD';
        } else {
            quality = 'MD';
        }

        const videoStreamingApi =
            Config.get('API_RUNTIME').CONTENTS_BY_CATEGORY;
        const videoStreamingParams = parseApiParams(
            Object.assign(
                {},
                videoStreamingApi.params,
                {
                    vh: 'cinema.kids.azercell.com', // MESSO A FUOCO SOLO PERCHÈ SERVE UNA VH VALIDA MA POI NON VIENE USATA
                    white_label: 'cinema.kids.azercell.com', // MESSO A FUOCO SOLO PERCHÈ SERVE UNA VH VALIDA MA POI NON VIENE USATA
                    content_id: this.props.itemID,
                    quality,
                    catalogID: this.props.catalogID || '',
                },
                this.props.catalogID && { customer_id: this.props.catalogID }
            )
        );

        console.log('videoStreamingParams', videoStreamingParams);
        console.log('videoStreamingApi', videoStreamingApi);
        console.log('API_RUNTIME', Config.get('API_RUNTIME'));

        fetcher
            .get(videoStreamingApi.url, videoStreamingParams, {
                credentials: 'include',
            })
            .then(response => {
                console.log('API Response:', response);
                if (response) {
                    const video = response; // Prendi il primo video
                    const clip = this.getVideoClip(video); // Usa la funzione per estrarre gli URL
                    console.log('Video Clip:', clip);

                    if (clip.mp4 || clip.m3u8) {
                        const videoUrl = clip.mp4 || clip.m3u8; // Priorità a mp4
                        const mimeType = clip.mp4
                            ? 'video/mp4'
                            : 'application/x-mpegURL'; // Imposta il MIME type in base all'URL
                        this.createPlayer(videoUrl, mimeType); // Crea il player con i dati estratti
                    } else {
                        Logger.error(
                            'VIDEOPLAYER',
                            'No valid video URL found in API response'
                        );
                    }
                } else {
                    Logger.error(
                        'VIDEOPLAYER',
                        'API response does not contain items'
                    );
                }
            })
            .catch(error => {
                Logger.error('VIDEOPLAYER', 'API call failed', error.message);
            });
    }

    getVideoClip(video) {
        console.log('Video clip URLs:', video.delivery_url);
        if (video && video.delivery_url) {
            const clip = {};
            Object.keys(video.delivery_url).forEach(key => {
                if (key.includes('m3u8')) {
                    clip.m3u8 = video.delivery_url[key];
                    console.log('m3u8', clip.m3u8);
                } else if (key.includes('mp4')) {
                    clip.mp4 = video.delivery_url[key];
                    console.log('mp4', clip.mp4);
                } else {
                    clip.default = video.delivery_url[key];
                }
            });
            return clip;
        }
        return {};
    }

    createPlayer(videoUrl, mimeType) {
        if (!this.mounted) {
            return;
        }

        const player = VideoPlayerLibrary.createPlayer({
            domNode: document.getElementById(videoPlayerNodeID),
            url: videoUrl,
            mime: mimeType,
            provider: 'newflowplayer',
            providerOptions: {
                autoplay: Config.get('VIDEO_PLAYERS_AUTOPLAY'),
                token: Config.get('FLOWPLAYER_TOKEN'),
                poster: this.props.poster,
            },
            events: {
                firstPlay: () => {
                    Logger.log('VIDEOPLAYER', 'firstPlay');
                    if (this.props.onFirstPlay) {
                        this.props.onFirstPlay();
                    }
                },
            },
        });

        this.setState({ playerId: player.id });
    }

    componentWillUnmount() {
        this.mounted = false;
        if (this.state.playerId) {
            VideoPlayerLibrary.clear(this.state.playerId);
        }
    }

    render() {
        return <Block id={videoPlayerNodeID}></Block>;
    }
}

VideoPlayer.propTypes = {
    //contentId: PropTypes.string.isRequired,
    //videoVh: PropTypes.string,
    //whiteLabel: PropTypes.string,
    itemID: PropTypes.string.isRequired,
    videoID: PropTypes.string.isRequired,
    catalogID: PropTypes.string,
    poster: PropTypes.string.isRequired,
    onFirstPlay: PropTypes.func,
    isWifi: PropTypes.bool.isRequired,
};

export default VideoPlayer;
