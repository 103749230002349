import Browser from '@docomodigital/js-browser';
import Config from '@docomodigital/js-config';
import Dictionary from '@docomodigital/js-dictionary';
import Logger from '@docomodigital/js-logger';
import User from '@productfe/react-user';
import Mfp from '@productfe/mfp';
import { ZoomIn } from 'animate-css-styled-components';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Analytics from '@docomodigital/js-analytics';
import NewtonAdapter from 'newton-adapter';
import { Helmet } from 'react-helmet';

/* COMPONENTS */
import Badge from 'components/Badge/Badge.jsx';
import Block from 'components/Block/Block.jsx';
import Button from 'components/Button/Button.jsx';
import Card from 'components/Card/Card.jsx';
import Container from 'components/Container/Container.jsx';
import Cover from 'components/Cover/Cover.jsx';
import Icon from 'components/Icon/Icon.jsx';
import List from 'components/List/List.jsx';
import Text from 'components/Text/Text.jsx';
import VideoPlayer from 'components/VideoPlayer/VideoPlayer.jsx';

/* LIBRARIES */
import imageSize from 'lib/formatter/imageSize';
import fetcher from 'lib/fetcher';

import { setConnection } from 'model/actions';
import store from 'model/store';

class Zoom extends Component {
    constructor(props) {
        super(props);

        this.mounted = true;

        this.state = {
            canUseLoaded: false,
            canUse: {},
            pony: '',
            storeUrl: '',
            showDownloadButton: false,
            isWifi: false,
        };

        // set title
        const customTitle = Dictionary.get(
            `SEO_WEBAPP_TITLE_ZOOM_${props.match.params.container.toUpperCase()}`
        );
        this.title =
            customTitle || Dictionary.get('SEO_WEBAPP_TITLE_ZOOM_DEFAULT');

        // initialize invisible button ref
        this.hiddenDownloadButton = null;
        this.imageSizes = Config.get('IMG_SIZES');
        this.loadCanUse = this.loadCanUse.bind(this);
        this.manageCanUse = this.manageCanUse.bind(this);
        this.trackContentEvent = this.trackContentEvent.bind(this);
        this.trackDownloadEvent = this.trackDownloadEvent.bind(this);
        this.generateDownloadUrl = this.generateDownloadUrl.bind(this);
        this.download = this.download.bind(this);
    }

    componentDidMount() {
        let connectionPromise;
        if (this.props.compileData[0].format === 'video') {
            connectionPromise = this.setUserConnection();
        } else {
            connectionPromise = Promise.resolve();
        }

        connectionPromise.then(wifi => {
            this.loadCanUse(this.props.compileData[0], wifi);

            if (this.props.compileData[0].format !== 'video') {
                this.generateDownloadUrl();
            }
        });
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    setUserConnection() {
        const {
            connection: { expiresAt, wifi },
        } = store.getState();
        const now = Math.floor(Date.now() / 1000);
        if (now > expiresAt) {
            return fetcher.get(Config.get('NETWORK_INFO_API_URL')).then(res => {
                const isWifi =
                    (res.networkType &&
                        res.networkType !== 'undefined' &&
                        res.networkType !== 'mobile') ||
                    false;
                store.dispatch(setConnection(isWifi));
                return isWifi;
            });
        } else {
            return Promise.resolve(wifi);
        }
    }

    loadCanUse(item, isWifi) {
        try {
            User.canUse(item.id, item).then(canUse => {
                // save canUse and set canUseLoaded: true
                if (!this.mounted) {
                    return;
                }
                this.setState({ canUseLoaded: true, canUse, isWifi }, () => {
                    // manageCanUse if content.when.onload is enabled
                    if (canUse.content.when && canUse.content.when.onload) {
                        this.manageCanUse();
                    }
                    if (typeof isWifi === 'boolean') {
                        // this.props.setConnection(isWifi);
                    }
                });
            });
        } catch (error) {
            Logger.error('CANUSE', error.message);
        }
    }

    manageCanUse() {
        try {
            switch (this.state.canUse.content.action) {
                case 'paywall':
                    throw new Error(
                        'Action "paywall" not supported for DDK framework'
                    );
                case 'addprofile':
                    if (Config.get('ROUTING.ROUTER_ADDEMAIL.path')) {
                        this.props.history.push(
                            Config.get('ROUTING.ROUTER_ADDEMAIL.path')
                        );
                    } else {
                        throw new Error(
                            'Action is "addprofile" but ROUTER_ADDEMAIL is not defined'
                        );
                    }
                    break;
                case 'redirect':
                    if (this.state.canUse.redirectURL) {
                        window.location.href = this.state.canUse.redirectURL;
                    } else {
                        throw new Error(
                            'Action is "redirect" but redirect URL is not defined'
                        );
                    }
                    break;
                default:
                    throw new Error(
                        'Missing or wrong "content.action" parameter'
                    );
            }
        } catch (error) {
            Logger.error('CANUSE', error.message);
        }
    }

    generateDownloadUrl() {
        // declaration of callback of MFP put (*)
        window._mfpPutCallbackZoom = resp => {
            Logger.log('MFP', 'put callback', resp);
            if (!this.mounted) {
                return;
            }
            this.setState({
                storeUrl: Mfp.generateRedirectUrl(
                    this.state.pony,
                    this.props.compileData[0].appid || ''
                ),
                showDownloadButton: true,
            });
        };

        if (User.isLogged()) {
            NewtonAdapter.getTransientToken((err, transientTokenResp) => {
                if (err) {
                    Logger.error('MFP', 'getTransientToken', err.message);
                } else {
                    // create pony
                    Mfp.createPony({
                        userId: transientTokenResp || '',
                        sessionId: NewtonAdapter.getSessionId() || '',
                    })
                        .then(createPonyResp => {
                            if (!this.mounted) {
                                return;
                            }
                            this.setState(
                                {
                                    pony: createPonyResp.ponyUrl,
                                },
                                () => {
                                    Logger.log(
                                        'MFP',
                                        'create pony',
                                        createPonyResp.ponyUrl
                                    );
                                    // start MFP flow and, after, call callback of MFP put (*)
                                    Mfp.startMfpFlow(
                                        createPonyResp.ponyUrl,
                                        '_mfpPutCallbackZoom'
                                    );
                                }
                            );
                        })
                        .catch(function (error) {
                            Logger.error('MFP', 'create pony', error.message);
                        });
                }
            });
        } else {
            window._mfpPutCallbackZoom({
                message:
                    'User is not logged, no mfp.put has been called in ZoomPage',
            });
        }
    }

    download(e) {
        e.preventDefault();

        // tracking
        this.trackContentEvent();
        this.trackDownloadEvent();

        // download app
        this.hiddenDownloadButton.click(e);
    }

    trackContentEvent() {
        const eventName = 'Content';
        Analytics.trackEvent({
            category: 'Zoom',
            action: eventName,
            label: this.props.compileData[0].title,
        });
        NewtonAdapter.trackEvent({
            name: eventName,
            properties: {
                content_type: this.props.compileData[0].format,
                content_ID: this.props.compileData[0].id,
                content_title: this.props.compileData[0].title,
            },
        });
    }

    trackDownloadEvent() {
        const eventName = 'DownloadApp';
        Analytics.trackEvent({
            category: 'Zoom',
            action: eventName,
            label: this.props.compileData[0].title,
        });
        NewtonAdapter.trackEvent({
            name: eventName,
            properties: {
                content_type: this.props.compileData[0].format,
                content_ID: this.props.compileData[0].id,
                content_title: this.props.compileData[0].title,
            },
        });
    }

    render() {
        const item = this.props.compileData[0];

        /* TODO: replace with this.props.history.goBack ? */
        const closeLink =
            Browser.getPrevPage() || Config.get('ROUTING.ROUTER_HOME.path');

        /********************
         *  RELATED ITEMS
         ********************/

        const relatedItems = this.props.compileData[1].map(
            (relatedItem, index) => {
                return (
                    <Card
                        key={index}
                        id={relatedItem.id}
                        link={relatedItem.url_zoom}
                        title={relatedItem.title}
                        imageProps={{
                            src: relatedItem.images.cover.ratio_1_4,
                            ratio: 'mix',
                            width: this.imageSizes.cover.medium,
                            alt: relatedItem.title,
                        }}
                        container={relatedItem.container}
                        contentType={relatedItem.format}
                        borderRadius={[1]}
                        p={1}
                        fromPage="Zoom"
                        eventName="Content_click"
                    />
                );
            }
        );

        /********************
         *  COVER / VIDEO
         ********************/

        let cover = null;
        if (this.state.canUseLoaded) {
            if (item.format === 'video' && this.state.canUse.allow) {
                // (if video) -> video player
                const firstVideoIndex = item.video_gallery[0];
                const firstVideo = item.videos_list[firstVideoIndex];
                console.log('item', item);
                console.log('firstVideo', firstVideo);
                cover = (
                    <VideoPlayer
                        isWifi={this.state.isWifi}
                        itemID={item.id}
                        videoID={firstVideo.video_id}
                        catalogID={(this.props.selectedContainer || {}).catalog}
                        poster={imageSize(
                            item.images.cover.ratio_1_4,
                            'mix',
                            this.imageSizes.cover.large
                        )}
                        onFirstPlay={this.trackContentEvent}
                    />
                );
            } else {
                // (if !video) -> cover -> null / manageCanUse
                cover = (
                    <Cover
                        onClick={() => {
                            if (!this.state.canUse.allow) {
                                this.manageCanUse();
                            }
                        }}
                        maxWidth="480px"
                        m="auto"
                        imageProps={{
                            src: item.images.cover.ratio_1_4,
                            ratio: 'mix',
                            width: this.imageSizes.cover.large,
                            alt: item.title,
                        }}
                        border={[2, 3, 3]}
                        borderRadius={1}
                        borderColor="w"
                    />
                );
            }
        }

        /********************
         *  CALLTOACTION
         ********************/

        let callToAction = null;
        if (this.state.canUseLoaded) {
            if (item.format === 'video') {
                if (this.state.canUse.allow) {
                    // (if video && !allow) -> no call-to-action
                } else {
                    // (if video && allow) -> button "play video" -> manageCanUse
                    callToAction = (
                        <Block
                            position="relative"
                            bottom="15px"
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            data-mipqa="access-content"
                        >
                            <Button
                                onClick={this.manageCanUse}
                                linearGradient={['bgButton', 'bgButton']}
                                color="colorZoomButton"
                                boxShadow={5}
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                border={[0]}
                                borderRadius={[0]}
                                borderColor="borderColorZoom"
                            >
                                <Text
                                    is="span"
                                    display="flex"
                                    color="colorZoomButton"
                                    fontSize={[8]}
                                    alignItems="center"
                                    justifyContent="center"
                                    text-transform="capitalize"
                                >
                                    {Dictionary.get('WEBAPP_DDC_BUTTON_WATCH')}{' '}
                                </Text>
                            </Button>
                        </Block>
                    );
                }
            } else {
                if (this.state.showDownloadButton) {
                    // (if !video && showDownloadButton) -> button "download" -> download / manageCanUse
                    callToAction = (
                        <Block
                            position="relative"
                            bottom="15px"
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            data-mipqa="access-content"
                        >
                            <Button
                                onClick={e =>
                                    this.state.canUse.allow
                                        ? this.download(e)
                                        : this.manageCanUse()
                                }
                                linearGradient={['bgButton', 'bgButton']}
                                color="colorZoomButton"
                                boxShadow={5}
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                border={[0]}
                                borderRadius={[0]}
                                borderColor="borderColorZoom"
                            >
                                <Text
                                    is="span"
                                    display="flex"
                                    color="colorZoomButton"
                                    fontSize={[8]}
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    {Dictionary.get(
                                        'WEBAPP_DDC_BUTTON_DOWNLOAD'
                                    )}{' '}
                                </Text>
                            </Button>

                            {/*eslint-disable-next-line*/}
                            <a
                                id="hiddenDownloadButton"
                                ref={hiddenRef => {
                                    this.hiddenDownloadButton = hiddenRef;
                                }}
                                style={{ display: 'none' }}
                                href={`javascript:window.location.href='${this.state.storeUrl}'; 0;`}
                            >
                                invisibleButton
                            </a>
                        </Block>
                    );
                }
            }
        }

        /********************
         *  RENDER
         ********************/

        return [
            <Helmet key="helmet">
                <title>
                    {item.title} {this.title}
                </title>
                <meta name="description" content={item.description} />
            </Helmet>,
            <ZoomIn key="template" duration="0.4s">
                <Block
                    bg={this.props.selectedContainer.theme.mainContainerBg}
                    pt={6}
                    pr={1}
                    pb={6}
                    pl={1}
                    width="100%"
                >
                    <Container overflow="hidden" maxWidth={0} display="table">
                        <Block display="table-cell">
                            <Badge
                                src={this.props.selectedContainer.images.badge}
                                alt={this.props.selectedContainer.name}
                                maxWidth={[2, 3, 3]}
                            />
                        </Block>
                        <Text
                            data-mipqa="zoom-title"
                            is="h1"
                            fontSize={[9, 3, 3]}
                            textAlign="left"
                            pl={1}
                            width="100%"
                            display="table-cell"
                            verticalAlign="middle"
                            color={
                                this.props.selectedContainer.theme
                                    .secondaryColor
                            }
                        >
                            {item.title}
                        </Text>
                        <Link to={closeLink}>
                            <Icon
                                data-mipqa="zoom-close"
                                content="close"
                                fontSize={[1, 2, 2]}
                                display="table-cell"
                                verticalAlign="middle"
                                textAlign="right"
                                color={
                                    this.props.selectedContainer.theme
                                        .secondaryColor
                                }
                                pt={[4, 6, 6]}
                            />
                        </Link>
                    </Container>
                </Block>

                <Block
                    bg={this.props.selectedContainer.theme.mainContainerBg}
                    position="relative"
                    pb={2}
                    pl={1}
                    pr={1}
                    boxShadow={1}
                >
                    <Container maxWidth={0}>
                        {cover}

                        {callToAction}
                    </Container>
                </Block>

                <Block boxShadow={1} p={1} pt={[6]} pb={[6]}>
                    <Container maxWidth={0}>
                        <Text
                            data-mipqa="zoom-description"
                            is="p"
                            fontSize={[4, 2, 2]}
                            pb={4}
                        >
                            {item.description}
                        </Text>
                        {/* <Text is='span' fontSize={[ 1, 4]} p={[0]}
                            bg={this.props.selectedContainer.theme.mainContainerBg}
                            color={this.props.selectedContainer.theme.secondaryColor}>
                            {item.category.title}
                        </Text> */}
                    </Container>
                </Block>

                {relatedItems.length ? (
                    <Block>
                        <Text
                            is="h2"
                            fontSize={[3, 3]}
                            mt={2}
                            mb={1}
                            textAlign="center"
                            uppercase
                            ellipsis
                        >
                            <Text
                                is="span"
                                display="inline-block"
                                bg="transparent"
                                borderRadius={2}
                                height={6}
                                boxShadow={[3, 4]}
                            >
                                {Dictionary.get('WEBAPP_DDC_RELATED_VIDEO')}
                            </Text>
                        </Text>
                        <Container maxWidth={0}>
                            <List columns={[2, 3, 4, 4]}>{relatedItems}</List>
                        </Container>
                    </Block>
                ) : null}
            </ZoomIn>,
        ];
    }
}

const mapStateToProps = (state, props) => {
    return {
        selectedContainer: state.containers.filter(
            container => container.container === props.match.params.container
        )[0],
    };
};

export default connect(mapStateToProps)(Zoom);
